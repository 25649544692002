import * as React                        from "react"
import Layout                            from "../components/layout";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage }         from "gatsby-plugin-image";
import { useEffect, useState } from "react";

import '../styles/index.scss';
import useWindowSize                     from "../hooks/useWindowSize";

const useRandomImage = (images, pos, offset) => {
	const [ index, setIndex ] = useState(false);
	const [ nextIndex, setNextIndex ] = useState(false);

	const [ isAnimating, setIsAnimating ] = useState(false);

	const cIndex = (index === false) ? (pos*9 + 1) % images.length : index;
	const nIndex = (nextIndex === false) ? (pos*9 + 2) % images.length : nextIndex;

	useEffect(() => {
		let timeout;
		let timeout2;
		let timeout3;
		const time = 6000;
		const changeTime = 700;

		const delayedSwap = () => {
			setNextIndex( Math.floor(Math.random() * 10000000) % images.length );
			setIsAnimating(false);
		}

		const startAnimation = () => {
			setIsAnimating(true)
		}

		const callback = () => {
			setIndex( nextIndex );

			timeout = setTimeout(callback, time);
			timeout2 = setTimeout(startAnimation, time - changeTime);
			timeout3 = setTimeout(delayedSwap, (time - changeTime)/2);
		};



		timeout = setTimeout(callback, time + offset);
		timeout2 = setTimeout(startAnimation, time - changeTime + offset);

		return () => {
			clearTimeout(timeout);
			clearTimeout(timeout2);
			clearTimeout(timeout3);
		}
	}, [setIndex, setNextIndex, setIsAnimating, nextIndex])

	if(index === false && nextIndex === false) {
		setIndex(cIndex);
		setNextIndex(nIndex);
	}

	return {
		key: images[cIndex].Key,
		nextKey: images[nIndex].Key,
		image: images[cIndex].gatsbyImageData,
		galleryName: images[cIndex].GalleryName,
		nextImage: images[nIndex].gatsbyImageData,
		nextGalleryName: images[nIndex].GalleryName,
		isAnimating
	};
}

const IndexPage = () => {
	const contextData = useStaticQuery(graphql`{
	  	s3SourcedPage(Name: {eq: "home"}) {
			remotePage {
			  	childMarkdownRemark {
					html
			  	}
			}
	  	}
	  	allS3SourcedGallery {
			nodes {
			  	GalleryName
			  	childrenS3SourcedImage {
					Key
					Name
					remoteImage {
					  	childImageSharp {
							gatsbyImageData(width: 1920)
					  	}
					}
			  	}
			}
	  	}
	}`)

	const allImages = contextData.allS3SourcedGallery.nodes.reduce((previousValue, currentValue) => {
		return previousValue.concat(currentValue.childrenS3SourcedImage.map(el => {
			return {
				Key: el.Key,
				Name: el.Name,
				gatsbyImageData: el.remoteImage.childImageSharp.gatsbyImageData,
				GalleryName: currentValue.GalleryName
			}
		}))
	}, [])

	const size = useWindowSize();
	const gridSize = Math.min(Math.max(Math.round((size.width - 300)/300), 1), 3) || 3;

	const images = [
		useRandomImage(allImages, 1, -1000),
		useRandomImage(allImages, 2, 3000),
		useRandomImage(allImages, 3, 1000)
	];

	return (
		<Layout title="Jędrek Wojnar F.o.t.o.g.r.a.f.i.a" isSlim={true}>
			<div className={'showcase-wrapper'}>
				{ images.slice(0, gridSize).map(({ image, galleryName, nextImage, nextGalleryName, isAnimating, key, nextKey }, index) => {
					return (
						<div key={index} className={`showcase-tile ${isAnimating ? 'animate' : '' }`}>
							<Link className={'showcase-current'} to={`/gallery/${encodeURI(galleryName)}`}>
								<span className={'showcase-caption'}>{ galleryName }</span>
								<GatsbyImage alt={''} image={image}/>
							</Link>
							<Link className={'showcase-next'} to={`/gallery/${encodeURI(nextGalleryName)}`}>
								<span className={'showcase-caption'}>{ nextGalleryName }</span>
								<GatsbyImage alt={''} image={nextImage}/>
							</Link>
						</div>
					)
				}) }
			</div>
		</Layout>
	)
}

export default IndexPage
